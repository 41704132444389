import axios, { AxiosResponse } from "axios"
import {
  ArgsOf,
  check,
  either,
  FnBase,
  FnUnary,
  pipe,
  result,
  trycatchAsync,
} from "tiinvo"

export const baseURL = (base = "") => {
  if (typeof window === "undefined") {
    return base
  }

  const islocalhost = window.location.host.includes("localhost")
  const istestwww = window.location.host.includes("test.totenpass.com")
  const istest = islocalhost || istestwww
  const root = istest ? `https://test.totenpass.com` : `https://www.totenpass.com`

  return root + base
}

export const create = (baseURL = ``) => {
  const client = axios.create({
    baseURL,
    withCredentials: true,
  })

  return client
}

type StatusHandleFn<Left, Right> = FnUnary<
  result.Result<AxiosResponse<any>>,
  either.Either<Left, Right>
>
type StatusHandlerMap<Left, Right> = Record<
  number,
  FnUnary<any, either.Either<Left, Right>>
>

const mapstatus = <Left, Right>(
  statushandler: StatusHandlerMap<Left, Right>
) => (response: AxiosResponse) => {
  const fn = check(
    response.status in statushandler,
    `unhandled status`
  )(statushandler[response.status])
  return fn(response.data)
}

export const mapresponse = <Left, Right>(
  statushandler: StatusHandlerMap<Left, Right>
): StatusHandleFn<Left, Right> =>
  pipe(
    result.map(mapstatus(statushandler)),
    result.unwrapOr(
      either.left<string[]>([`unhandled error`]) as any
    )
  )

export const withstatushandler = <Left, Right>(
  handler: StatusHandleFn<Left, Right>
) => <Fn extends FnBase>(fn: Fn) => (
  ...args: ArgsOf<Fn>
): Promise<either.Either<Left, Right>> =>
  trycatchAsync<any, any>(fn, ...args).then(handler as any)
